import React, { useState, useContext } from "react";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { sendPasswordResetEmail } from "firebase/auth";
import { SnackbarContext } from "../../Context/SnackBarProvider";
import { AuthContext } from "../../Context/AuthProvider";
import { Typography } from "@mui/material";

export const ForgotPassword = () => {
  const { setSnackbar } = useContext(SnackbarContext);
  const { auth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbar({
          text: "Password reset email sent",
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbar({
          text: "Unable to send reset email",
          severity: "error",
        });
      });
  };

  return (
    <>
      <Link href="#" onClick={handleOpen} underline="hover" variant="h6">
        Forgot password?
      </Link>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">Password reset</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ paddingBottom: "20px" }}
          >
            <Typography variant="h5">
              Enter your email and we'll send you a link to reset your password
            </Typography>
          </DialogContentText>
          <TextField
            required
            label="Email"
            inputProps={{
              style: { fontSize: 24, marginBottom: "-5px" },
            }}
            InputLabelProps={{
              sx: {
                "&.MuiInputLabel-root": { fontSize: 24 },
              },
            }}
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              resetPassword();
            }}
          >
            <Typography variant="h6" style={{ marginBottom: "-5px" }}>
              Reset password
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
