import { getStorage, ref, uploadBytesResumable, getDownloadURL, updateMetadata, deleteObject } from "firebase/storage";

export const getRandomName = () => {
    const nameLength = 32;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < nameLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const uploadPattern = async (pattern, destination, customMetadata) => {
    const fileName = `${getRandomName()}.pdf`;
    const metadata = {
        contentType: 'application/pdf',
        customMetadata: customMetadata
    };

    const storage = getStorage();
    const fileRef = ref(storage, `${destination}/${fileName}`);

    const uploadTask = uploadBytesResumable(fileRef, pattern, metadata);

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                        console.log('Upload state: ', snapshot.state);
                        break;
                }
            },
            (error) => {
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
}

export const updateMetaData = async (fileName, customMetadata) => {
    const destination = 'singlePatterns';

    // Create a reference to the file whose metadata we want to change
    const storage = getStorage();
    const patternRef = ref(storage, `${destination}/${fileName}.pdf`);

    const metadata = {
        customMetadata: customMetadata
    };

    // Update metadata properties
    updateMetadata(patternRef, metadata)
    .then((metadata) => {
        console.log('Metadata updated successfully', metadata);
    }).catch((error) => {
    });
}

export const deletePattern = async (fileName, callback) => {
    const destination = 'singlePatterns';
    const storage = getStorage();
    const patternRef = ref(storage, `${destination}/${fileName}.pdf`);
    deleteObject(patternRef)
    .then(() => {
        callback();
    }).catch((error) => {
        callback();
    });
}