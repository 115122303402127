import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  BOOK_INFO_DEFAULTS,
  MEASUREMENT_UNITS,
  FRACTIONS,
} from "../../js/constants";
import { PatternContext } from "../../Context/PatternProvider";
import {
  getHeightCM,
  getHeightInchPrimary,
  getHeightInchFraction,
} from "../../js/numbers";

export const BookDetails = (props) => {
  const { onNextClick, onBackClick } = props;

  const { lastOddPageState, heightState, measurementUnitState } =
    useContext(PatternContext);

  const [lastOddPage, setLastOddPage] = lastOddPageState;
  const [height, setHeight] = heightState;
  const [measurementUnit, setMeasurementUnit] = measurementUnitState;

  const onChangeMeasurementUnit = (e) => {
    setMeasurementUnit(e.target.value);
  };

  const Height = {
    value: height,
    valueCM: getHeightCM(height),
    valueInchPrimary: getHeightInchPrimary(height),
    valueInchFraction: getHeightInchFraction(height).value,
    onChangeCM: (e) => {
      const rounded = Math.round(e.target.value * 10) / 10;
      const height = Number(rounded) * 10;
      setHeight(height);
    },
    onChangeInchPrimary: (e) => {
      const primary = Math.round(e.target.value);
      const fraction = getHeightInchFraction(height).value;
      const heightInch = primary + parseFloat(fraction);
      const heightMM = Math.round(heightInch * 25.4);
      setHeight(heightMM);
    },
    onChangeInchFraction: (e) => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(e.target.value);
      const heightInch = primary + fraction;
      const heightMM = Math.round(heightInch * 25.4);
      setHeight(heightMM);
    },
    isValidCM: () => {
      return (
        height && //non-empty
        height >= 25 && //greater than 0
        height <= BOOK_INFO_DEFAULTS.MAX_HEIGHTMM && //less than 260
        height % 1 === 0 && //no decimals
        height.toString().length <= 5
      ); //prevent many 0s
    },
    isValidInch: () => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(getHeightInchFraction(height).value);
      const sum = primary + fraction;
      return (
        getHeightInchPrimary(height) && //non-empty
        getHeightInchPrimary(height) > 0 && //greater than 0
        sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH && //less than 10.25
        getHeightInchPrimary(height) % 1 === 0 && //no decimals
        getHeightInchPrimary(height).toString().length <= 5
      ); //prevent many 0s
    },
    isValidInchFraction: () => {
      const primary = Math.round(getHeightInchPrimary(height));
      const fraction = parseFloat(getHeightInchFraction(height).value);
      const sum = primary + fraction;
      return sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH; //less than 10.25
    },
  };
  const LastOddPage = {
    value: lastOddPage,
    onChange: (e) => {
      setLastOddPage(Number(e.target.value).toString());
    },
    isValid: () => {
      return (
        lastOddPage && //non-empty
        lastOddPage % 2 && //odd
        lastOddPage > 0 && //greater than 0
        lastOddPage <= BOOK_INFO_DEFAULTS.MAX_LAST_ODD_PAGE && //less than 5000
        lastOddPage % 1 === 0 && //no decimals
        lastOddPage.toString().length <= 5
      ); //prevent many 0s
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px",
        borderRadius: "15px",
        backgroundColor: "#FEFEFF",
        padding: "16px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Height.png"}
          alt=""
          style={{ height: "150px", width: "200px" }}
        />

        <div style={{ display: "flex", maxWidth: "167px" }}>
          {measurementUnit === "cm" && (
            <TextField
              variant="standard"
              label={
                <Typography variant="h4" style={{ marginTop: "-10px" }}>
                  Page height
                </Typography>
              }
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*",
                style: { fontSize: 24, marginBottom: "-5px" },
              }}
              style={{
                width: "90px",
                whiteSpace: "nowrap",
              }}
              color="primary"
              type="number"
              step="1"
              value={Height.valueCM}
              onChange={Height.onChangeCM}
              error={!Height.isValidCM()}
              InputLabelProps={{
                sx: {
                  "&.MuiInputLabel-root": { overflow: "visible" },
                },
              }}
              helperText={
                !Height.isValidCM() && (
                  <Typography>That value is invalid</Typography>
                )
              }
            />
          )}

          {measurementUnit === "inch" && (
            <>
              <TextField
                variant="standard"
                label={
                  <Typography variant="h4" style={{ marginTop: "-10px" }}>
                    Page height
                  </Typography>
                }
                inputProps={{
                  inputMode: "decimal",
                  pattern: "[0-9]*",
                  style: {
                    fontSize: 24,
                    marginBottom: "-5px",
                  },
                }}
                style={{
                  width: "50px",
                  whiteSpace: "nowrap",
                }}
                color="primary"
                type="number"
                step="1"
                value={Height.valueInchPrimary}
                onChange={Height.onChangeInchPrimary}
                error={!Height.isValidInch()}
                InputLabelProps={{
                  sx: { "&.MuiInputLabel-root": { overflow: "visible" } },
                }}
                helperText={
                  !Height.isValidInch() && (
                    <Typography>That value is invalid</Typography>
                  )
                }
              />
              <TextField
                select
                variant="standard"
                label=" "
                value={Height.valueInchFraction}
                onChange={Height.onChangeInchFraction}
                style={{ minWidth: "35px" }}
              >
                {FRACTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {
                      <Typography
                        style={{ fontSize: 23, marginBottom: "-5px" }}
                      >
                        {option.label}
                      </Typography>
                    }
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          <TextField
            select
            variant="standard"
            label=" "
            value={measurementUnit}
            onChange={onChangeMeasurementUnit}
            style={{ minWidth: "75px" }}
          >
            {MEASUREMENT_UNITS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {
                  <Typography style={{ fontSize: 23, marginBottom: "-5px" }}>
                    {option.label}
                  </Typography>
                }
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <img
          src={process.env.PUBLIC_URL + "/images/Pages.png"}
          alt=""
          style={{ height: "150px", width: "200px" }}
        />
        <TextField
          variant="standard"
          label={
            <Typography variant="h4" style={{ marginTop: "-10px" }}>
              Last odd page
            </Typography>
          }
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: { fontSize: 24, marginBottom: "-5px" },
          }}
          color="primary"
          type="number"
          step="1"
          value={LastOddPage.value}
          onChange={LastOddPage.onChange}
          error={!LastOddPage.isValid()}
          helperText={
            !LastOddPage.isValid() && (
              <Typography>That value is invalid</Typography>
            )
          }
        />
      </div>

      <div style={{ flexGrow: 1 }} />

      <div style={{ display: "flex", gap: "16px" }}>
        <Button variant="outlined" onClick={onBackClick}>
          <Typography variant="h5" style={{ marginBottom: "-5px" }}>
            Back
          </Typography>
        </Button>
        <Button
          variant="contained"
          style={{ boxShadow: "none" }}
          disabled={!Height.isValidCM() || !LastOddPage.isValid()}
          onClick={onNextClick}
        >
          <Typography variant="h5" style={{ marginBottom: "-5px" }}>
            Next
          </Typography>
        </Button>
      </div>
    </div>
  );
};
