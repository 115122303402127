import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AccountDrawer from "../../Account/AccountDrawer/AccountDrawer";
import { AuthContext } from "../../../Context/AuthProvider";

export const TopBar = () => {
  const auth = useContext(AuthContext);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#334047",
          }}
        >
          <div>{auth?.user && <div style={{ width: "28px" }} />}</div>
          <img
            src={process.env.PUBLIC_URL + "/LogoWhite.png"}
            alt="Fore Edge Book Art"
            style={{ maxWidth: "50px", maxHeight: "50px", cursor: "pointer" }}
            onClick={() => {
              window.location.href = "/";
            }}
          />
          <div>{auth?.user && <AccountDrawer />}</div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
