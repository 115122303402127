const booksRaw = [
  {
    createdBy: "Sara Fedor",
    imgPath: process.env.PUBLIC_URL + "books/Book1.jpg",
    flag: "🇨🇦",
  },
  {
    createdBy: "Sara Fedor",
    imgPath: process.env.PUBLIC_URL + "books/Book2.jpg",
    flag: "🇨🇦",
  },
  {
    createdBy: "Linda Peterson",
    imgPath: process.env.PUBLIC_URL + "books/Book3.jpg",
    flag: "🇦🇺",
  },
  {
    createdBy: "Coleen Matthews",
    imgPath: process.env.PUBLIC_URL + "books/Book4.jpg",
    flag: "🇿🇦",
  },
  {
    createdBy: "Sindy Paulin",
    imgPath: process.env.PUBLIC_URL + "books/Book5.jpg",
    flag: "🇺🇸",
  },
  {
    createdBy: "Felicita Castiglioni",
    imgPath: process.env.PUBLIC_URL + "books/Book6.jpg",
  },
  {
    createdBy: "Rosa Burguillos",
    imgPath: process.env.PUBLIC_URL + "books/Book7.jpg",
    flag: "🇪🇸",
  },
];

const books = booksRaw
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

export { books };
