import React, { useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthProvider";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

const VIEW = {
  SIGNUP: "SIGNUP",
  SIGNIN: "SIGNIN",
};

export const SignInUp = () => {
  const { user } = useContext(AuthContext);
  const [view, setView] = useState(VIEW.SIGNUP);

  if (user === null) {
    return (
      <>
        <Modal
          open={true}
          onClose={() => {}}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(3px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Button
                variant={view === VIEW.SIGNUP ? "contained" : "text"}
                onClick={() => {
                  setView(VIEW.SIGNUP);
                }}
                style={{ minWidth: "100px" }}
              >
                <Typography variant="h6" style={{ marginBottom: "-5px" }}>
                  Sign Up
                </Typography>
              </Button>
              <Button
                variant={view === VIEW.SIGNIN ? "contained" : "text"}
                onClick={() => {
                  setView(VIEW.SIGNIN);
                }}
                style={{ minWidth: "100px" }}
              >
                <Typography variant="h6" style={{ marginBottom: "-5px" }}>
                  Sign In
                </Typography>
              </Button>
            </div>
            {view === VIEW.SIGNUP ? (
              <SignUp
                onLinkClick={() => {
                  setView(VIEW.SIGNIN);
                }}
              />
            ) : (
              <SignIn
                onLinkClick={() => {
                  setView(VIEW.SIGNUP);
                }}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
};
