import React, { useRef } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";

const ImageBrowseButton = (props) => {
  const { callback, imageDataURL, id } = props;
  const fileInput = useRef(null);

  const handleChange = (event) => {
    event.preventDefault();
    var reader = new FileReader();

    const file = fileInput.current.files[0];

    if (!typeof file === "Blob") {
      alert("Please upload an image file.");
      return;
    }

    if (file.type === "image/gif") {
      alert("GIFs are not supported. Please upload a different image type.");
      return;
    }

    reader.readAsDataURL(fileInput.current.files[0]);
    reader.onload = function (e) {
      const dataURL = e.target.result;
      callback(dataURL);
    };
  };

  return (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id={id}
        type="file"
        ref={fileInput}
        onChange={handleChange}
      />
      <label htmlFor={id}>
        <Button
          component="span"
          variant="contained"
          size="large"
          color="primary"
          endIcon={<CloudUploadIcon />}
          style={{ marginBottom: "10px" }}
        >
          <Typography variant="h5" style={{ marginBottom: "-5px" }}>
            Browse
          </Typography>
        </Button>
      </label>
      {imageDataURL && (
        <div style={{ position: "relative", width: "300px", height: "250px" }}>
          <img
            src={imageDataURL}
            alt=""
            style={{
              maxHeight: "250px",
              maxWidth: "300px",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              margin: "auto",
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImageBrowseButton;
