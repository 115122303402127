import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled, { keyframes } from "styled-components";

const growShrink = keyframes`
    0%, 100% {
        transform: scale(1);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    }
`;

const AnimatedButton = styled(Button)`
  background-color: #83c021;
  margin: 10px;
  font-size: 25px;
  padding-top: 12px;
  animation: ${growShrink} 2s infinite;
  &:hover {
    background-color: #6fa017;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
`;

export const StartPatternButton = () => {
  return (
    <ButtonContainer>
      <AnimatedButton
        variant="contained"
        size="large"
        onClick={() => {
          window.location.href = "/patternmaker";
        }}
      >
        <Typography variant="h5">Start Pattern Maker</Typography>
      </AnimatedButton>
    </ButtonContainer>
  );
};
