import { Typography } from "@mui/material";
import React from "react";
import styled, { keyframes } from "styled-components";
import { books } from "../../js/books";

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-100% + 100vw)); }
`;

const ScrollingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  animation: ${scroll} 20s linear infinite alternate;
`;

export const Carousel = () => {
  const moreBooks = books.concat(books);

  return (
    <div
      style={{
        width: "100vw",
        overflowX: "hidden",
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      <ScrollingContainer>
        {moreBooks.map((book, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={book.imgPath}
              alt={book.createdBy}
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                <span>{`${book.createdBy} `}</span>
                <span style={{ verticalAlign: "middle", lineHeight: "28px" }}>
                  {book.flag || ""}
                </span>
              </div>
            </Typography>
          </div>
        ))}
      </ScrollingContainer>
    </div>
  );
};
