export const getFileNameAndMediaTokenFromURL = (url) => {
    const regex = /singlePatterns%2F([a-zA-Z0-9]+)\.pdf\?alt=media&token=([a-f0-9-]+)/;

    const matches = url.match(regex);

    if (matches) {
        const n = matches[1];
        const mt = matches[2];
        return {n, mt};
    } else {
        console.log("No matches found.");
    }
};

export const getURLFromFileNameAndMediaToken = (fileName, mediaToken) => {
    const url = `https://firebasestorage.googleapis.com/v0/b/lotus-book-image.appspot.com/o/singlePatterns%2F${fileName}.pdf?alt=media&token=${mediaToken}`;
    return url;
};

export const getSinglePatternURLFromFileNameAndMediaToken = (fileName, mediaToken) => {
    if (!fileName) {
        return '';
    }
    const baseUrl = window.location.origin
    let url = `${baseUrl}/sp?n=${fileName}`;
    if (!!mediaToken) {
        url += `&mt=${mediaToken}`;
    }
    return url;
};