import React, { createContext, useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";

export const SnackbarContext = createContext();

export const SnackbarProvider = (props) => {
  const [snackbar, setSnackbar] = useState(null);
  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {props.children}
      {snackbar && (
        <CustomizedSnackbar
          callback={() => {
            setSnackbar(null);
          }}
          {...snackbar}
        />
      )}
    </SnackbarContext.Provider>
  );
};
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbar = (props) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    props.callback();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={props.severity}>
        <Typography
          variant="h6"
          style={{
            lineHeight: "1.5rem",
          }}
        >
          {props.text}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
