import React, { useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../../Context/AuthProvider";

export const SignedInCheck = (props) => {
  const { LandingPage, Main } = props;
  const { user } = useContext(AuthContext);

  if (user === undefined) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }
  if (user) {
    return <Main />;
  } else {
    return <LandingPage />;
  }
};
