import { BOOK_INFO_DEFAULTS, FRACTIONS } from "../js/constants";

export const formatPrice = (price) => {
  price /= Math.pow(10, 2);
  return price.toFixed(2);
};

export const mmToPoints = (mm) => {
  //72 points per inch
  return (mm * 720) / 254;
};

export const getHeightCM = (heightMM) => {
  let heightCM = heightMM / 10;
  return String(parseFloat(heightCM.toFixed(1))).replace(/^0+/, "");
};

export const getHeightInchPrimary = (heightMM) => {
  let heightInch = heightMM / 25.4;
  if (parseFloat(heightInch.toFixed(2) % 1) >= 0.97) {
    return String(parseFloat(Math.ceil(heightInch)));
  }
  return String(parseFloat(Math.floor(heightInch)));
};

export const getHeightInchFraction = (heightMM) => {
  const heightInch = heightMM / 25.4;
  const fractionDecimal = (heightInch % 1).toFixed(3);

  if (heightMM > BOOK_INFO_DEFAULTS.MAX_HEIGHTMM) {
    return FRACTIONS[1];
  }

  if (
    fractionDecimal >= FRACTIONS[0].value &&
    fractionDecimal < parseFloat(FRACTIONS[1].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[0];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[1].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[2].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[1];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[2].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[3].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[2];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[3].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[4].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[3];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[4].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[5].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[4];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[5].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[6].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[5];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[6].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[7].value) - parseFloat(1 / 16)
  ) {
    return FRACTIONS[6];
  } else if (
    fractionDecimal >= parseFloat(FRACTIONS[7].value) - parseFloat(1 / 16) &&
    fractionDecimal < parseFloat(FRACTIONS[7].value) + parseFloat(1 / 16)
  ) {
    return FRACTIONS[7];
  } else {
    return FRACTIONS[0];
  }
};
