import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { PatternInputProvider } from "../../Context/PatternProvider";
import { STEP } from "../../js/constants";
import Stepper from "./Shared/Stepper";
import { TopBar } from "./Shared/TopBar";
import { BookDetails } from "./BookDetails";
import { BrowseImage } from "./BrowseImage";
import { PrintPattern } from "./PrintPattern";
import { SignInUp } from "../Account/SignInUp";

const Main = () => {
  const [activeStep, setActiveStep] = useState(STEP.BrowseImage);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const renderTitle = () => {
    let title;
    switch (activeStep) {
      case STEP.BrowseImage:
      default:
        title = "Let's start by uploading an image";
        break;
      case STEP.BookDetails:
        title = "Great! Now let's get some book info";
        break;
      case STEP.PrintPattern:
        title = "Your pattern is ready!";
        break;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </div>
    );
  };

  const renderStep = () => {
    let step;
    switch (activeStep) {
      case STEP.BrowseImage:
      default:
        step = <BrowseImage onNextClick={handleNext} />;
        break;
      case STEP.BookDetails:
        step = (
          <BookDetails onNextClick={handleNext} onBackClick={handleBack} />
        );
        break;
      case STEP.PrintPattern:
        step = (
          <PrintPattern onResetClick={handleReset} onBackClick={handleBack} />
        );
        break;
    }
    return step;
  };

  return (
    <>
      <TopBar />
      <Container style={{ marginTop: "16px" }}>
        <SignInUp />
        <PatternInputProvider>
          <Stepper activeStep={activeStep} />
          {renderTitle()}
          {renderStep()}
        </PatternInputProvider>
      </Container>
    </>
  );
};

export default Main;
