import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../Context/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { STRIPE_ROLES } from "../../js/constants";

export const SubscribedCheck = (props) => {
  const states = {
    LOADING: 0,
    NOT_SUBSCRIBED: 1,
    SUBSCRIBED: 2,
  };

  const { subscribed, notSubscribed } = props;
  const [view, setView] = useState(states.LOADING);
  const { auth } = useContext(AuthContext);

  const getView = () => {
    switch (view) {
      case states.LOADING:
      default:
        return <CircularProgress />;
      case states.NOT_SUBSCRIBED:
        return notSubscribed;
      case states.SUBSCRIBED:
        return subscribed;
    }
  };

  useEffect(() => {
    async function getCustomClaimRole() {
      await auth.currentUser.getIdToken(true);
      await auth.currentUser.getIdTokenResult().then((decodedToken) => {
        if (decodedToken.claims.stripeRole === STRIPE_ROLES.PAYING_USER) {
          setView(states.SUBSCRIBED);
        } else {
          setView(states.NOT_SUBSCRIBED);
        }
      });
    }
    getCustomClaimRole();
  }, []);

  return getView();
};
