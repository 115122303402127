import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Carousel } from "./Carousel";
import { FeatureList } from "./FeatureList";
import { AutoPlayImage } from "./AutoPlayImage";
import { Container } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { StartPatternButton } from "./StartPatternButton";
import { HowToSteps } from "./HowToSteps";

export const LandingPage = () => {
  return (
    <>
      {/* Toolbar */}
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          background: "transparent",
          backdropFilter: "blur(3px)",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: "10px",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/Logo.png"}
              alt="Fore Edge Book Art"
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
              }}
            />

            <Typography
              variant="h3"
              component="h2"
              style={{
                fontWeight: 500,
                letterSpacing: "1px",
                color: "#4B2E39",
              }}
            >
              Fore Edge Book Art
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      {/* Hero */}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage:
            "linear-gradient(180deg, rgba(255,245,225,1) 20%, rgba(201,216,197,1) 80%)",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        {/* Text */}
        <div
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: "1px",
              color: "#775562",
            }}
          >
            Make your own
          </Typography>

          <Typography
            sx={{
              typography: { xs: "h3", sm: "h2", md: "h1" },
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: "5px",
              color: "#4B2E39",
              lineHeight: { xs: "30px", sm: "45px", md: "60px" },
            }}
          >
            Fore Edge Book Art
          </Typography>

          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: "1px",
              color: "#775562",
              marginBottom: "40px",
            }}
          >
            with any image
          </Typography>

          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#83C021",
              margin: "10px",
              fontSize: "25px",
              paddingTop: "12px",
              marginBottom: "40px",
            }}
            onClick={() => {
              window.location.href = "/patternmaker";
            }}
          >
            <Typography variant="h5">Start Pattern Maker</Typography>
          </Button>

          <Typography
            variant="subtitle"
            component="h2"
            sx={{
              textAlign: "center",
              fontWeight: 500,
              letterSpacing: "1px",
              marginBottom: { xs: "0px", md: "40px" },
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <i>
              Create easy to follow patterns for your next gift or craft project
            </i>
          </Typography>
        </div>
      </div>
      {/* Video */}
      <Container
        style={{
          marginTop: "-30vh",
        }}
      >
        <CardMedia
          component="video"
          src={process.env.PUBLIC_URL + "/videos/video.webm"}
          style={{
            boxShadow: "5px 5px 20px 0px rgba(0,0,0,0.5)",
            borderRadius: "10px",
          }}
          autoPlay
          muted
          loop
        />
      </Container>
      {/* Logo */}
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/Logo.png"}
          alt="Fore Edge Book Art"
          style={{
            maxWidth: "200px",
            maxHeight: "200px",
          }}
        />
      </Container>
      {/* Carousel */}
      <Carousel />
      {/* Feature List */}
      <Container>
        <FeatureList />
      </Container>
      {/* Picture */}
      <Container>
        <AutoPlayImage />
      </Container>
      {/* Link to Group */}
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "80px",
            marginBottom: "80px",
          }}
        >
          <Typography
            variant="h2"
            style={{
              textAlign: "center",
              lineHeight: "42px",
              marginBottom: "20px",
            }}
          >
            Join our{" "}
            <span
              style={{
                background: "linear-gradient(90deg, #FF7E5F, #FEB47B)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              vibrant
            </span>{" "}
            community
          </Typography>
          <Typography
            variant="h5"
            style={{ maxWidth: "850px", textAlign: "center" }}
          >
            Connect with thousands of fore edge book art enthusiasts worldwide!
            Share, learn, and celebrate this unique craft with friendly,
            supportive members{" "}
            <Link
              href="https://www.facebook.com/share/g/15LzBAKnYB/"
              underline="hover"
            >
              in our Facebook group
            </Link>
            . Whether you're a beginner or an expert, there's a place for you
            here!
          </Typography>
        </div>
      </Container>
      {/* Start Pattern Maker Button */}
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "120px",
          marginBottom: "40px",
        }}
      >
        <StartPatternButton />
      </Container>
      {/* How to steps */}
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        <HowToSteps />
      </Container>
      {/* Footer */}
      <footer
        style={{
          backgroundColor: "#333",
          color: "#FFF",
          padding: "20px 0",
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          Like us on{" "}
          <Link
            href="https://www.facebook.com/ForeEdgeBookArt"
            underline="hover"
            style={{ color: "#3399FF" }}
          >
            Facebook
          </Link>
        </Typography>
        <Typography variant="h6">
          Have questions? Contact{" "}
          <Link
            href="mailto:matt@foreedgebookart.com"
            underline="hover"
            style={{ color: "#3399FF" }}
          >
            Matt@ForeEdgeBookArt.com
          </Link>
        </Typography>
      </footer>
      {/* FAQ */}
    </>
  );
};
