import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import StepIcon from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";

const CustomStepIcon = styled(StepIcon)(({ theme, active }) => ({
  fontSize: "42px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiStepIcon-text": {
    fontSize: "1.2rem",
  },
}));

const FIMEPopover = () => {
  const [anchorFIME, setAnchorFIME] = useState(null);

  const handleFIMEClick = (event) => {
    setAnchorFIME(event.currentTarget);
  };
  const handleFIMEClose = () => {
    setAnchorFIME(null);
  };
  const isOpen = Boolean(anchorFIME);
  const id = isOpen ? "fime-popover" : undefined;

  return (
    <>
      <button
        type="button"
        style={{
          background: "none",
          border: "none",
          padding: 0,
          color: "#83c021",
          cursor: "pointer",
          fontFamily: "Dongle",
          fontSize: "inherit",
        }}
        onClick={handleFIMEClick}
      >
        Image
      </button>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorFIME}
        onClose={handleFIMEClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "images/FIME.jpg"}
          alt="FIME"
          style={{ maxWidth: "250px", marginBottom: "-10px" }}
        />
      </Popover>
    </>
  );
};

const STEPS = [
  {
    title: "Materials Needed",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>
          Fore Edge Book Art Pattern - Start the{" "}
          <a href="/patternmaker" style={{ color: "#83c021" }}>
            Pattern Maker
          </a>
        </li>
        <li>Inkjet Printer</li>
        <li>Paper - we suggest using 80-100gsm or 28lb paper</li>
        <li>
          Paper cutter - we suggest the{" "}
          <a
            href="https://cutterpillar.co/?ref=pmrzlorq"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#83c021" }}
          >
            Cutterpillar Pro
          </a>
        </li>
        <li>Book</li>
      </ul>
    ),
  },
  {
    title: "Print Your Pattern",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>Print your pattern at 100% or 'Actual size'</li>
        <li>Ensure 'Fit to page' is unchecked</li>
      </ul>
    ),
    image: `${process.env.PUBLIC_URL}/images/1-Print.jpg`,
  },
  {
    title: "Cut the Strips",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>Trim the top and bottom edges of each page</li>
        <li>Cut strips along the long solid lines</li>
      </ul>
    ),
    image: `${process.env.PUBLIC_URL}/images/2-Cut.jpg`,
  },
  {
    title: "Fold the Strips",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>Fold each strip in half lengthwise</li>
        <li>
          Use the{" "}
          <a
            href="https://www.facebook.com/share/g/15LzBAKnYB/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#83c021" }}
          >
            FIME by Monica Osborne
          </a>{" "}
          (<FIMEPopover />) to make folding easy!
        </li>
      </ul>
    ),
    image: `${process.env.PUBLIC_URL}/images/3-Fold.jpg`,
  },
  {
    title: "Apply the Strips",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>Align folded strip 1 with the outer edge of page 1</li>
        <li>
          Attach strips to the page using tape or glue - we suggest a permanent
          glue roller
        </li>
        <li>
          Continue attaching the strips in sequence on the odd pages of the book
        </li>
      </ul>
    ),
    image: `${process.env.PUBLIC_URL}/images/4-Apply.jpg`,
  },
  {
    title: "Display Your Finished Book",
    description: (
      <ul style={{ paddingLeft: "20px" }}>
        <li>Once all strips are applied, admire your work!</li>
      </ul>
    ),
    image: `${process.env.PUBLIC_URL}/images/5-Display.jpg`,
  },
];

export const HowToSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
          lineHeight: "42px",
          marginBottom: "20px",
        }}
      >
        Create your own{" "}
        <span
          style={{
            background: "linear-gradient(90deg, #FF7E5F, #FEB47B)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Fore Edge Book Art
        </span>{" "}
      </Typography>

      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ width: "80%" }}
      >
        {STEPS.map((step, index) => (
          <Step key={index} completed={activeStep > index}>
            <StepLabel
              StepIconComponent={(props) =>
                props.completed ? (
                  <Check style={{ color: "#83c021", fontSize: "2rem" }} />
                ) : (
                  <CustomStepIcon
                    {...props}
                    style={{ color: index === activeStep ? "#83c021" : "#ccc" }}
                  />
                )
              }
            >
              <Typography
                variant="h4"
                style={{
                  color: "#775562",
                  fontWeight: 500,
                  letterSpacing: "1px",
                  textAlign: "left",
                }}
              >
                {step.title}
              </Typography>
            </StepLabel>
            <StepContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    color: "#775562",
                    fontWeight: 500,
                    letterSpacing: "1px",
                    textAlign: "left",
                    flex: 1,
                    minWidth: "50%",
                  }}
                >
                  {step.description}
                </Typography>
                {step.image && (
                  <img
                    src={step.image}
                    alt={step.title}
                    style={{
                      maxHeight: "30vh",
                      minWidth: "250px",
                      boxShadow: "5px 5px 20px 0px rgba(0,0,0,0.5)",
                      borderRadius: "10px",
                      marginLeft: "20px",
                    }}
                  />
                )}
              </div>
              <div style={{ marginTop: "10px" }}>
                {index !== 0 && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#757575", // Matching grey
                      color: "#fff",
                      marginRight: "10px",
                    }}
                    onClick={handleBack}
                  >
                    <Typography variant="h5" style={{ marginBottom: "-5px" }}>
                      Back
                    </Typography>
                  </Button>
                )}
                {index !== STEPS.length - 1 && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#83c021", // Darker green
                      color: "#fff",
                    }}
                    onClick={handleNext}
                  >
                    <Typography variant="h5" style={{ marginBottom: "-5px" }}>
                      Next
                    </Typography>
                  </Button>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === STEPS.length && (
        <Paper
          square
          elevation={0}
          style={{ padding: "20px", marginTop: "20px" }}
        >
          <Typography variant="h5" style={{ textAlign: "center" }}>
            All steps completed - you're finished!
          </Typography>
          <Button
            onClick={handleReset}
            style={{ display: "block", margin: "20px auto" }}
          >
            Reset
          </Button>
        </Paper>
      )}
    </Container>
  );
};
