import React, { useState, useContext } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { SnackbarContext } from "../../Context/SnackBarProvider";
import { AuthContext } from "../../Context/AuthProvider";

export const SignUp = (props) => {
  const { onLinkClick } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setSnackbar } = useContext(SnackbarContext);
  const { auth } = useContext(AuthContext);

  const onSignUpClick = () => {
    createUserWithEmailAndPassword(auth, email.trim(), password)
      .then((userCredential) => {
        const user = userCredential.user;
        setSnackbar({
          text: "Welcome!",
          severity: "success",
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message.replace("Firebase: ", "");
        setSnackbar({
          text: errorMessage,
          severity: "error",
        });
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "300px",
        minWidth: "250px",
        alignItems: "center",
      }}
    >
      <Typography variant="h3">Create Account</Typography>
      <TextField
        required
        label={"Email"}
        inputProps={{
          style: { fontSize: 24, marginBottom: "-5px" },
        }}
        InputLabelProps={{
          sx: {
            "&.MuiInputLabel-root": { fontSize: 24 },
          },
        }}
        variant="standard"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <TextField
        required
        label="Password"
        inputProps={{
          style: { fontSize: 24, marginBottom: "-5px" },
        }}
        InputLabelProps={{
          sx: {
            "&.MuiInputLabel-root": { fontSize: 24 },
          },
        }}
        variant="standard"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Button
        variant="contained"
        size="medium"
        onClick={onSignUpClick}
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          backgroundColor: "#83c021",
        }}
      >
        <Typography variant="h6" style={{ marginBottom: "-5px" }}>
          Sign Up
        </Typography>
      </Button>
      <Link onClick={onLinkClick} underline="hover" variant="h6">
        Already have an account?
      </Link>
    </div>
  );
};
