import { useState } from "react";
import Typography from "@mui/material/Typography";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { books } from "../../js/books";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const AutoPlayImage = () => {
  const [activeImageStep, setActiveImageStep] = useState(0);

  const handleImageStepChange = (step) => {
    setActiveImageStep(step);
  };

  return (
    <AutoPlaySwipeableViews
      axis="x"
      index={activeImageStep}
      onChangeIndex={handleImageStepChange}
      style={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      {books.map((step, index) => (
        <div
          key={index} // Add key prop here
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={books[index].imgPath}
            alt={books[index].createdBy}
            style={{
              maxHeight: "70vh",
              maxWidth: "100%",
              boxShadow: "5px 5px 20px 0px rgba(0,0,0,0.5)",
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
            }}
          />

          <Typography
            variant="h4"
            style={{
              margin: "10px",
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                margin: "10px",
                gap: "10px",
              }}
            >
              <span>{`Made by ${step.createdBy} `}</span>
              <span style={{ verticalAlign: "middle", lineHeight: "35px" }}>
                {step.flag || ""}
              </span>
            </div>
          </Typography>
        </div>
      ))}
    </AutoPlaySwipeableViews>
  );
};
