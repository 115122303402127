import MenuBookIcon from '@mui/icons-material/MenuBook';
import ImageIcon from '@mui/icons-material/Image';
import PrintIcon from '@mui/icons-material/Print';
import LinearProgress from '@mui/material/LinearProgress';

function Stepper(props) {
    const { activeStep } = props;
    const activeColor = "#FFFFFF";
    const inactiveColor = "#99A2A9";

    return (
        <>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircleIcon active={activeStep >= 0}>
                    <ImageIcon style={{color: activeStep >= 0 ? activeColor : inactiveColor, fontSize: 30}} />
                </CircleIcon>
                
                <LinearProgress style={{flex: 1}} variant="determinate" value={activeStep > 0 ? 100 : 0} />

                <CircleIcon active={activeStep >= 1}>
                    <MenuBookIcon style={{color: activeStep >= 1 ? activeColor : inactiveColor, fontSize: 30}} />
                </CircleIcon>

                <LinearProgress style={{flex: 1}} variant="determinate" value={activeStep > 1 ? 100 : 0} />

                <CircleIcon active={activeStep >= 2}>
                    <PrintIcon style={{color: activeStep >= 2 ? activeColor : inactiveColor, fontSize: 30}} />
                </CircleIcon>
            </div>
        </>
    );
};

function CircleIcon(props) {
    const {active} = props;
    const styling = {
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"};

    if (active) {
        styling.backgroundImage = "linear-gradient(135deg, #ee0979, #ff6a00)";
    } else {
        styling.backgroundColor = "#DEE7EC";
    }

    return (
        <div style={styling}>
                {props.children}
        </div>
    );
};

export default Stepper;