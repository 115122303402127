import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from "@mui/icons-material/Done";
import Modal from "@mui/material/Modal";
import { isMobile } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Purchase } from "./Purchase";
import { SubscribedCheck } from "../Account/SubscribedCheck";
import { SendEmailButton } from "../EmailPattern/SendEmailButton";
import { PatternContext } from "../../Context/PatternProvider";
import { IMAGETYPE } from "../../js/constants";

export const PrintPattern = (props) => {
  const { onResetClick, onBackClick } = props;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
          borderRadius: "15px",
          backgroundColor: "#FEFEFF",
          padding: "16px",
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <SubscribedCheck
          subscribed={<PrintPatternButton isSubscribed={true} {...props} />}
          notSubscribed={<PrintPatternButton isSubscribed={false} {...props} />}
        />
        <div style={{ flexGrow: 1 }} />
        <div style={{ display: "flex", gap: "16px" }}>
          <Button variant="outlined" onClick={onBackClick}>
            <Typography variant="h5" style={{ marginBottom: "-5px" }}>
              Back
            </Typography>
          </Button>
          <Button variant="outlined" onClick={onResetClick}>
            <Typography variant="h5" style={{ marginBottom: "-5px" }}>
              Reset
            </Typography>
          </Button>
        </div>
      </div>
    </>
  );
};

const PrintPatternButton = (props) => {
  const { isSubscribed } = props;
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(null);
  const [document, setDocument] = useState(null);
  const {
    GetPattern,
    GetSamplePattern,
    imagePrimaryDataURLState,
    imageSecondaryDataURLState,
    imageTypeState,
  } = useContext(PatternContext);
  const [imagePrimaryDataURL] = imagePrimaryDataURLState;
  const [imageSecondaryDataURL] = imageSecondaryDataURLState;
  const [imageType] = imageTypeState;

  useEffect(() => {
    const fetchPattern = async () => {
      const { url, blob } = isSubscribed
        ? await GetPattern()
        : await GetSamplePattern();
      setUrl(url);
      setDocument(blob);
      setLoading(false);
    };
    fetchPattern();
  }, [GetPattern, GetSamplePattern, isSubscribed]);

  const openPattern = (safeURL) => {
    if (isMobile) {
      var newWin = window.open(safeURL);
      if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
        alert("Please disable your popup blocker and try again.");
      }
    } else {
      setShowModal(true);
    }
  };

  const PatternModal = () => {
    return (
      <>
        {showModal && (
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(3px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "15px",
                margin: "20px",
                width: "100%",
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                aria-label="Menu"
                onClick={() => {
                  setShowModal(false);
                }}
                style={{
                  alignSelf: "flex-end",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <CloseIcon />
              </IconButton>
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-start",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  href={url}
                  variant="outlined"
                  size="small"
                  download
                  endIcon={<DownloadIcon />}
                >
                  <Typography variant="h5" style={{ marginBottom: "-5px" }}>
                    Download
                  </Typography>
                </Button>
                {isSubscribed && <SendEmailButton pattern={document} />}
              </div>
              <iframe
                src={url}
                title="Pattern Preview"
                style={{
                  height: "80vh",
                  width: "100%",
                }}
              />
            </div>
          </Modal>
        )}
      </>
    );
  };

  const PatternPreview = () => {
    return (
      <div style={{ display: "flex" }}>
        <img src={imagePrimaryDataURL} alt="" style={{ maxHeight: "100px" }} />
        {imageType === IMAGETYPE.DOUBLE && (
          <img
            src={imageSecondaryDataURL}
            alt=""
            style={{ maxHeight: "100px" }}
          />
        )}
      </div>
    );
  };

  const onGetPatternClick = () => {
    openPattern(url);
  };

  if (isSubscribed) {
    return (
      <>
        <PatternPreview />
        <Button
          variant="contained"
          size="large"
          disabled={loading}
          style={{
            margin: "10px",
            backgroundColor: loading ? "#ccc" : "#83C021",
          }}
          onClick={onGetPatternClick}
          endIcon={
            loading ? (
              <CircularProgress style={{ height: "20px", width: "20px" }} />
            ) : (
              <DoneIcon />
            )
          }
        >
          <Typography variant="h5" style={{ marginBottom: "-5px" }}>
            Open
          </Typography>
        </Button>
        <PatternModal />
      </>
    );
  } else {
    return (
      <>
        <PatternPreview />
        <Purchase />
        <Tooltip
          title={
            <Typography variant="h6">
              Only part of your pattern will be generated
            </Typography>
          }
        >
          <Button
            variant="text"
            size="medium"
            disabled={loading}
            style={{ margin: "10px" }}
            onClick={onGetPatternClick}
          >
            <Typography variant="h5" style={{ marginBottom: "-5px" }}>
              No thanks
            </Typography>
          </Button>
        </Tooltip>
        <PatternModal />
      </>
    );
  }
};
