import { useContext, useState, useEffect } from "react";
import { FirebaseAppContext } from "../../../Context/FirebaseAppProvider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CircularProgress from "@mui/material/CircularProgress";
import { getStripePortalURL } from "../../../js/account";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

export const ManageSubscription = (props) => {
  const { callBack } = props;
  const [url, setURL] = useState(null);
  const firebaseApp = useContext(FirebaseAppContext);

  useEffect(() => {
    const getURL = async () => {
      const url = await getStripePortalURL(firebaseApp);
      setURL(url);
    };
    getURL();
  }, [firebaseApp]);

  const onManageSubscriptionClick = async () => {
    callBack();
    window.location.assign(url);
  };

  if (url === null) {
    return (
      <ListItem key={"Subscription"} disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <CircularProgress size={20} />
          </ListItemIcon>
          <ListItemText
            primary={"Manage account"}
            primaryTypographyProps={{ variant: "h5" }}
          />
        </ListItemButton>
      </ListItem>
    );
  } else if (url === undefined) {
    return null;
  } else if (url === "error") {
    return (
      <Tooltip
        title={
          <Typography variant="h5">
            Continue pattern creation to purchase
          </Typography>
        }
      >
        <ListItem key={"Subscription"} disablePadding>
          <ListItemButton disabled onClick={() => {}}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Manage account"}
              primaryTypographyProps={{ variant: "h5" }}
            />
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  } else {
    return (
      <ListItem key={"Subscription"} disablePadding>
        <ListItemButton
          onClick={() => {
            onManageSubscriptionClick();
          }}
        >
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText
            primary={"Manage account"}
            primaryTypographyProps={{ variant: "h5" }}
          />
        </ListItemButton>
      </ListItem>
    );
  }
};
